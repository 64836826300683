<template>
  <div>
    <el-tabs v-model="activeName">
      <el-card>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-input placeholder="别名">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="7">
            <el-input placeholder="借走状态">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primay" @click="getBankBorrowList()">查询</el-button>
            <el-button type="primay">重置</el-button>
          </el-col>
        </el-row>
        <!-- 按钮区 -->
        <el-row :gutter="20" style="margin:9px 2px">
          <el-button type="danger" icon="el-icon-add" size="min">新增支出</el-button>
          <el-button type="danger" icon="el-icon-edit" size="min">修改支出</el-button>
          <el-button type="danger" icon="el-icon-edit" size="min">调整</el-button>
          <el-button type="danger" icon="el-icon-edit" size="min">借出记录</el-button>
          <el-button type="danger" icon="el-icon-edit" size="min">存款列表</el-button>
          <el-button type="danger">删除</el-button>
          <el-button type="danger">查看详情</el-button>
        </el-row>
        <!-- 列表区域 -->
        <el-table :data="bankBorrowList" border stripe>
          <el-table-column label="序号" align="center" width="65">
            <template slot-scope="scope">
              <el-radio
                :label="scope.$index"
                v-model="radio"
                @change.native="getCurrentRow(scope.row)"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column label="支行名称" prop="subbranch" />
          <el-table-column label="借走人的别名" prop="borrowAlias" />
          <el-table-column label="借走日期" prop="borrowData" />
          <el-table-column label="借走备注" prop="borrowRemark" />
          <el-table-column label="还给开始时间" prop="returnBeginData" />
          <el-table-column label="还给结束时间" prop="returnEndData" />
          <el-table-column label="借走资金" prop="borrowMoney" />
          <el-table-column label="最后还款金额" prop="returnMoney" />
          <el-table-column label="借走状态" prop="borrowStatus" />
          <el-table-column label="是否一定归还" prop="isMustBack" />
          <el-table-column label="是否作废" prop="isCancel" />
        </el-table>
      </el-card>
    </el-tabs>
  </div>
</template>
<script>
export default {
  name: "bankEstimateList",
  data() {
    return {
      //默认第一个选项卡
      activeName: "first",
      bankBorrowList: [],
      id: null,
      radio: null
    };
  },
  mounted() {
    console.log("tab4组件");

    // this.getBankBorrowList();
  },
  methods: {
    // async getBankBorrowList() {
    //   const { data: res } = await this.$http.get(
    //     "xun/fund/xunBankBorrow/list",
    //     {
    //       params: this.queryParam
    //     }
    //   );
    //   if (res.code !== '200') return this.$message.error("获取基金信息失败");
    //   this.bankBorrowList = res.result;
    // },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      console.log(row);
      this.id = row.id;
      this.templateSelection = row;
    }
  }
};
</script>
